import React from "react";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SignalCellularAlt2BarIcon from "@mui/icons-material/SignalCellularAlt2Bar";
import SignalCellularAlt1BarIcon from "@mui/icons-material/SignalCellularAlt1Bar";

const calculateDifferenceInSeconds = (lastUpdate) => {
  const now = new Date();
  const lastUpdateDate = new Date(lastUpdate);
  const diffInSeconds = Math.floor((now - lastUpdateDate) / 1000);
  return diffInSeconds;
};

const SignalIcon = ({ latestData }) => {
  const diffInSeconds = latestData
    ? calculateDifferenceInSeconds(latestData.last_update)
    : null;

  let signalIcon;
  if (diffInSeconds === null) {
    signalIcon = (
      <>
        <SignalCellularAlt1BarIcon style={{ color: "gray", zIndex: 1 }} />
        <span style={{ color: "gray", marginLeft: "5px" }}>Déconnecté</span>
      </>
    );
  } else if (diffInSeconds <= 60) {
    signalIcon = (
      <>
        <SignalCellularAltIcon style={{ color: "green", zIndex: 1 }} />
        <span style={{ color: "green", marginLeft: "5px" }}>
          Connexion stable
        </span>
      </>
    );
  } else if (diffInSeconds <= 120) {
    signalIcon = (
      <>
        <SignalCellularAlt2BarIcon style={{ color: "orange", zIndex: 1 }} />
        <span style={{ color: "orange", marginLeft: "5px" }}>
          Connexion instable
        </span>
      </>
    );
  } else {
    signalIcon = (
      <>
        <SignalCellularAlt1BarIcon style={{ color: "gray" }} />
        <span style={{ color: "gray", marginLeft: "5px" }}>Déconnecté</span>
      </>
    );
  }

  return <>{signalIcon}</>;
};

export default SignalIcon;
