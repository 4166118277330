import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SignalIcon from "../NavBar/SignalIcon";

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
});


const Data = () => {
  const [latestData, setLatestData] = useState(null);

  useEffect(() => {
      const fetchData = async (quote) => {
        try {
          const response = await api.get('/data_live');
          const data_live = response.data[0] || null;
          setLatestData(data_live);
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      };

      fetchData();
      const interval = setInterval(fetchData, 20000);

      return () => clearInterval(interval);
    }, []);
  
  return (
    <Box style={{ color: "black", position: "relative" }}>
      <div>
        <p style={{ display: "flex", alignItems: "center" }}>
          <SignalIcon latestData={latestData} />
        </p>
      </div>
    </Box>
  );
};

export default Data;