import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  formatDateForDisplay,
  formatDateForDatabase,
} from "../../utils/dateUtils";
import axios from "axios";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import type_market from "./marketTypes";
import command_status_text from "./CommandStatus";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import {
  formatToTwoDecimals,
  Prix,
  Prix_Brute_C,
  Prix_Net_C,
  Total_cmd,
} from "../../utils/Calculation";
import { useFetchData } from "../../utils/useFetchData";
import { formatNumberForDisplay } from "../../utils/FormatNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AuthContext } from "../../authentification/auth/AuthProvider";

const API_URL = process.env.REACT_APP_API_URL;


const NavireTable = ({ id_navire, reloadTrigger, setReloadTrigger,productCoefficient, productCoefficient_c }) => {
  const { username, role, fullname } = useContext(AuthContext);  

  const [data, setData] = useState([]);
  const {
    data: deliveries,
    loading: loadingDeliveries,
    error: deliveriesError,
  } = useFetchData(`${API_URL}/deliveries`);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    navireId: null,
  });
  const [editDialog, setEditDialog] = useState({ open: false, item: null });
  const [editedValues, setEditedValues] = useState({});

  const deliveryLookup = React.useMemo(
    () =>
      deliveries.reduce((acc, delivery) => {
        acc[delivery.code] = delivery.name;
        return acc;
      }, {}),
    [deliveries]
  );

  const typeMarketLookup = React.useMemo(
    () =>
      type_market.reduce((acc, type) => {
        acc[type.value] = type.label;
        return acc;
      }, {}),
    []
  );

  const commandStatusLookup = React.useMemo(
    () =>
      command_status_text.reduce((acc, status) => {
        acc[status.value] = status.label;
        return acc;
      }, []),
    []
  );

  const fetchData = useCallback(async () => {
    if (!id_navire) return;
    try {
      const response = await axios.get(
        `${API_URL}/commands?id_navire=${id_navire}`
      );
      const formattedData = response.data.map((item) => ({
        ...item,
        date_cmd: formatDateForDisplay(item.date_cmd),
      }));
      setData(formattedData);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Erreur lors de la récupération des commandes",
        severity: "error",
      });
    }
  }, [id_navire]);

  useEffect(() => {
    fetchData();
  }, [fetchData, reloadTrigger]);

  const handleButtonClick = async (navireId) => {
    await handleCommandUpdate(
      navireId,
      20,
      "La commande a été validée avec succès"
    );
  };

  const handleCancelClick = async (navireId) => {
    await handleCommandUpdate(
      navireId,
      90,
      "La commande a été annulée avec succès"
    );
  };

  const handleCommandUpdate = async (navireId, status, successMessage) => {
    try {
      await axios.put(`${API_URL}/commands/${navireId}`, { status, updated_by:username });
      setSnackbar({ open: true, message: successMessage, severity: "success" });
      setReloadTrigger((prev) => prev + 1);
      fetchData();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Erreur lors de la mise à jour de la commande",
        severity: "error",
      });
    }
  };

  const handleDeleteClick = (navireId) => {
    setConfirmDialog({ open: true, navireId });
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/commands/${confirmDialog.navireId}`);
      setSnackbar({
        open: true,
        message: "La commande a été supprimée avec succès",
        severity: "success",
      });
      setReloadTrigger((prev) => prev + 1);
      fetchData();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Erreur lors de la suppression de la commande",
        severity: "error",
      });
    } finally {
      setConfirmDialog({ open: false, navireId: null });
    }
  };

  const handleEditClick = (item) => {
    setEditedValues(item);
    setEditDialog({ open: true, item });
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    
    setEditedValues((prev) => {

      const newValues = {
        ...prev,
        [name]:
          name === "nb_contrat" || name === "cours" ? Number(value) : value,
      };


      // Mettre à jour 'total' lorsque 'nb_contrat' ou 'cours' change
      if (name === "nb_contrat" || name === "cours") {
        const PN_B = Prix_Brute_C(newValues.cours,newValues.prime)
        const PN_C = Prix_Net_C(PN_B,productCoefficient)
        newValues.prix_un = PN_C;
        newValues.total = Total_cmd(
          newValues.nb_contrat,
          newValues.prix_un*productCoefficient_c
        );
        console.log(newValues.cours,newValues.prime,PN_B,PN_C,productCoefficient);
      }

      return newValues;
    });
  };

  const [tooltipOpenId , settooltipOpenId ] = React.useState(null);


  const handleTooltipOpen = (data) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(
        `Dear Sir,
        Thank you to pass our order:
        ${data.nb_contrat}c @${data.type_cmd !== 1 ? data.cours : ""} ${typeMarketLookup[data.type_cmd].substring(2)}`
      )
      .then(() => {
        settooltipOpenId(data.id);
        setTimeout(() => {
          settooltipOpenId(null);
        }, 2000); // Close tooltip after 5 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
    } else {
      console.error("Clipboard API is not supported in this browser.");
    }
  };
  
 

  const handleEditSubmit = async () => {
    try {
      // console.log(editedValues.date_cmd,formatDateForDatabase(editedValues.date_cmd));
      const updatedValues = {
        ...editedValues,
        date_cmd: formatDateForDatabase(editedValues.date_cmd),
        updated_by: username,
      };
      await axios.put(`${API_URL}/commands/${editedValues.id}`, updatedValues);
      setSnackbar({
        open: true,
        message: "La commande a été modifiée avec succès",
        severity: "success",
      });
      setReloadTrigger((prev) => prev + 1);
      fetchData();
      setEditDialog({ open: false, item: null });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Erreur lors de la mise à jour de la commande",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDialogClose = () => {
    setConfirmDialog({ open: false, navireId: null });
  };

  const handleEditDialogClose = () => {
    setEditDialog({ open: false, item: null });
  };

  if (deliveriesError) return <p>{deliveriesError}</p>;

  return (
    <Grid width="100%">
      <TableContainer sx={{ maxHeight: "100%", overflowY: "scroll" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            padding: "5px",
            textAlign: "start",
          }}
        >
          Liste des commandes
        </Typography>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Livraison</TableCell>
              <TableCell>Contrats</TableCell>
              <TableCell>Cours</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((cmd) => (
                <TableRow key={cmd.id} hover>
                  <TableCell>{cmd.date_cmd}</TableCell>
                  <TableCell>
                    {loadingDeliveries ? (
                      <CircularProgress size={24} />
                    ) : (
                      deliveryLookup[cmd.delivery] || "Unknown delivery"
                    )}
                  </TableCell>
                  <TableCell>
                    {cmd.nb_contrat}{" "}
                    {typeMarketLookup[cmd.type_cmd] || "Unknown type"}
                  </TableCell>

                  <TableCell>{formatNumberForDisplay(cmd.cours)}</TableCell>
                  <TableCell>{(formatNumberForDisplay(cmd.prix_un))}</TableCell>
                  <TableCell align="right">{(formatNumberForDisplay(cmd.total))}</TableCell>
                  <TableCell>
                    {commandStatusLookup[cmd.status] || "Unknown Condition"}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                    <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        key={cmd.id}
                        open={tooltipOpenId===cmd.id}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Message copié"
                        placement="top"
                      >
                        <IconButton
                          variant="contained"
                          size="small"
                          color="inherit"
                          onClick={() => handleTooltipOpen(cmd)}
                          disabled={cmd.status !== 10}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={() => handleButtonClick(cmd.id)}
                        disabled={cmd.status === 20}
                      >
                        <CheckBoxRoundedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => handleCancelClick(cmd.id)}
                        disabled={cmd.status === 90}
                      >
                        <DisabledByDefaultRoundedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        size="small"
                        color="warning"
                        onClick={() => handleEditClick(cmd)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteClick(cmd.id)}
                        disabled={cmd.status === 20}
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                     
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="left">
                  Aucune donnée disponible
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={confirmDialog.open} onClose={handleDialogClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette commande ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmDelete} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDialog.open} onClose={handleEditDialogClose}>
        <DialogTitle>Modifier la commande</DialogTitle>
        <DialogContent>
          <TextField
            key="date_cmd"
            margin="dense"
            name="date_cmd"
            label="Date"
            type="text"
            fullWidth
            variant="outlined"
            value={editedValues.date_cmd}
            // value={editedValues.date_cmd }
            onChange={handleEditChange}
          />

          <Select
            label="delivery"
            fullWidth
            margin="dense"
            name="delivery"
            value={editedValues.delivery}
            onChange={handleEditChange}
            required
          >
            {loadingDeliveries ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              Object.keys(deliveryLookup).map((key) => (
                <MenuItem key={key} value={key}>
                  {deliveryLookup[key]}
                </MenuItem>
              ))
            )}
          </Select>
          {["nb_contrat"].map((field) => (
            <TextField
              key={field}
              margin="dense"
              name={field}
              label="Contrats"
              type={field === "cours" ? "number" : "text"}
              fullWidth
              variant="outlined"
              value={editedValues[field] || ""}
              onChange={handleEditChange}
              disabled={field === "total" || field === "prix_un"}
            />
          ))}

          <Select
            label="type_cmd"
            margin="dense"
            fullWidth
            name="type_cmd"
            value={editedValues.type_cmd}
            onChange={handleEditChange}
            required
          >
            {loadingDeliveries ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              Object.keys(typeMarketLookup).map((key) => (
                <MenuItem key={key} value={key}>
                  {typeMarketLookup[key]}
                </MenuItem>
              ))
            )}
          </Select>

          <TextField
            key="cours"
            margin="dense"
            name="cours"
            label="cours"
            type="number"
            fullWidth
            variant="outlined"
            value={editedValues.cours}
            onChange={handleEditChange}
          />

          <TextField
            key="prix_un"
            margin="dense"
            name="prix_un"
            label="prix"
            type="number"
            fullWidth
            variant="outlined"
            value={editedValues.prix_un}
            onChange={handleEditChange}
            disabled
          />

          <TextField
            key="total"
            margin="dense"
            name="total"
            label="total"
            type="number"
            fullWidth
            variant="outlined"
            value={editedValues.total}
            onChange={handleEditChange}
            disabled
          />

          <Select
            label="Status"
            fullWidth
            name="status"
            value={editedValues.status}
            onChange={handleEditChange}
            required
          >
            {loadingDeliveries ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              Object.keys(commandStatusLookup).map((key) => (
                <MenuItem key={key} value={key}>
                  {commandStatusLookup[key]}
                </MenuItem>
              ))
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} size="small" color="primary">
            Annuler
          </Button>
          <Button
            onClick={handleEditSubmit}
            size="small"
            variant="contained"
            color="success"
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default NavireTable;
