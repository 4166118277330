import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Popover,
  Badge,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ConditionManager from "../Alertes/ConditionComponent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import { useFetchData } from "../../utils/useFetchData";
import { AuthContext } from "../../authentification/auth/AuthProvider";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
});

const timeAgo = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const diff = Math.floor((now - date) / 1000);

  if (diff < 60) return `Maintenant`;
  if (diff < 3600) return `${Math.floor(diff / 60)} min`;
  if (diff < 86400) return `${Math.floor(diff / 3600)} h`;
  if (diff < 2592000) return `${Math.floor(diff / 86400)} j`;
  if (diff < 31536000) return `${Math.floor(diff / 2592000)} m`;
  return `${Math.floor(diff / 31536000)} a`;
};

const Notify = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const { username, role, fullname } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleClick = (event) => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      // Navigate to notifications page directly
      navigate("/notifications");
      readAllNotifications();
      setUnreadCount(0);
    } else {
      // Show popover for desktop
      setAnchorEl(event.currentTarget);
      fetchNotifications();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    readAllNotifications();
    setUnreadCount(0);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchNotifications = async () => {
    try {
      const response = await api.get(`/notifications?username=${username}`);
      setNotifications(response.data);
      setUnreadCount(response.data.filter((n) => !n.is_read).length);
    } catch (error) {
      console.error("Erreur lors de la récupération des notifications");
    }
  };

  const readAllNotifications = async () => {
    try {
      await api.patch(`/notifications/mark-all-as-read/${username}`);
    } catch (error) {
      console.error("Erreur lors de la récupération des notifications");
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: "10px", width: "350px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Notifications
          </Typography>
          {notifications.length > 0 ? (
            <>
              {notifications.slice(0, 3).map((notification) => (
                <Card key={notification.id} sx={{ boxShadow: "none" }}>
                  <CardActionArea>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: notification.is_read
                          ? "transparent"
                          : "#f1f1f1",
                        padding: 1,
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <CardHeader
                          sx={{ padding: 0 }}
                          action={
                            <Tooltip title="Time of notification" arrow>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {timeAgo(notification.created_at)}
                              </Typography>
                            </Tooltip>
                          }
                          title={
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                            >
                              {notification.name}
                            </Typography>
                          }
                          subheader={
                            <Typography variant="body2" color="text.secondary">
                              <ConditionManager
                                condi={notification.condition}
                                value={notification.value}
                              />
                            </Typography>
                          }
                        />
                        <CardContent sx={{ padding: 0 }}>
                          <Typography variant="body2" color="text.secondary">
                            {notification.message}.
                          </Typography>
                        </CardContent>
                      </Box>
                    </Box>
                  </CardActionArea>
                  <Divider />
                </Card>
              ))}
              {notifications.length > 3 && (
                <Box textAlign="center" mt={2}>
                  <Button
                    onClick={handleClose}
                    component={Link}
                    to="/notifications"
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "none" }}
                  >
                    Voir les notifications précédentes
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Card sx={{ boxShadow: "none" }}>
              <CardActionArea>
                <Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Aucune notification
                      </Typography>
                    </CardContent>
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default Notify;
