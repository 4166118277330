import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,Tooltip
} from "@mui/material";
import axios from "axios";
import ConditionManager from "../Alertes/ConditionComponent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import { AuthContext } from "../../authentification/auth/AuthProvider";
const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
});


const timeAgo = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const diff = Math.floor((now - date) / 1000); // Time difference in seconds

  if (diff < 60) return `Maintenant`;
  if (diff < 3600) return `${Math.floor(diff / 60)} min`;
  if (diff < 86400) return `${Math.floor(diff / 3600)} h`;
  if (diff < 2592000) return `${Math.floor(diff / 86400)} j`;
  if (diff < 31536000) return `${Math.floor(diff / 2592000)} m`;
  return `${Math.floor(diff / 31536000)} a`;
};

const NotificationList = () => {
  const { username, role, fullname } = useContext(AuthContext);

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);


  const fetchNotifications = async () => {
    try {
      const response = await api.get(`/notifications?username=${username}`);
      setNotifications(response.data);
      setUnreadCount(response.data.filter((n) => !n.is_read).length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleNotificationClick = (link) => {
    // Here you can implement navigation logic, e.g., using React Router
    window.location.href = link; // or use a router history.push if you're using React Router
    
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <div style={{ padding: "5px", width: "700px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" >
          <Typography variant="h4">Notifications</Typography>
        </Box>
        <React.Fragment>
      {notifications.length > 0 ? (
        <>
          {notifications.map((notification) => (
            <Card
              key={notification.id}
              sx={{
                marginBottom: 1.5,
                borderRadius: 1,
                boxShadow: 'none',
                
              }}
            >
              <CardActionArea >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <CardHeader
                      action={
                        <Tooltip title="Time of notification" arrow>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {timeAgo(notification.created_at)}
                          </Typography>
                        </Tooltip>
                      }
                      title={
                        <Typography variant="body1" fontWeight="bold" color="text.primary" justifySelf="start" >
                          {notification.name}
                        </Typography>
                      }
                      subheader={
                          <ConditionManager
                            condi={notification.condition}
                            value={notification.value}
                          />
                      }
                    />
                    <CardContent sx={{ paddingTop: 0 }}>
                      <Typography variant="body2" color="text.secondary">
                        Le dernier prix de <strong>{notification.name}</strong> est actuellement au prix d'alerte demandé : <strong>{notification.value}</strong>.
                      </Typography>
                    </CardContent>
                  </Box>
                </Box>
              </CardActionArea>
              <Divider />
            </Card>
          ))}
          
        </>
      ) : (
        <Typography variant="body1" textAlign="center" color="text.secondary" sx={{ marginTop: 2 }}>
          Aucun enregistrement trouvé
        </Typography>
      )}
    </React.Fragment>
      </div>
    </Box>
  );
};

export default NotificationList;
