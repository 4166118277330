import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Grid from "@mui/material/Grid2";
import NavireDetails from "./NavireDetails";
import { useFetchData } from "../../utils/useFetchData";
import { formatNumberForDisplay } from "../../utils/FormatNumber";
import { AuthContext } from "../../authentification/auth/AuthProvider";
import {
  formatDateForDisplay1,
  formatDateForDatabase,
} from "../../utils/dateUtils";

const API_URL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: API_URL,
});

const NavireManager = () => {
  const { username, role, fullname } = useContext(AuthContext);  

  const {
    data: navires,
    loading: loadingNavires,
    fetchData: fetchNavires,
  } = useFetchData(`${API_URL}/navires`);
  const { data: products, loading: loadingProducts } = useFetchData(
    `${API_URL}/products`
  );
  const { data: commandStat, loading: loadingcommandStat } = useFetchData(
    `${API_URL}/commands_stat?status=20`
  );
  const [selectedIdNavire, setSelectedIdNavire] = useState(0);
  const [selectedProduct, setselectedProduct] = useState(0);
  const [selectedNavire, setSelectedNavire] = useState(null);
  const [formData, setFormData] = useState(initialFormState());
  const [formLoading, setFormLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [filter, setFilter] = useState("active"); // Default filter is 'active'

  function initialFormState() {
    return {
      des: "",
      article: "",
      qte: "",
      contrat: "",
      status: 20,
      created_by: username,
      updated_by: username,
    };
  }

  useEffect(() => {
    if (filteredNavires.length > 0) {
      handleNavireClick(filteredNavires[0]); // Automatically select the first navire
    }
  }, [navires]);

  const filteredNavires = useMemo(() => {
    return navires.filter(
      (navire) =>
        (filter === "active" && navire.status < 90) ||
        (filter === "archived" && navire.status >= 90)
    );
  }, [navires, filter]);

  const handleNavireClick = (navire) => {
    setSelectedNavire(navire);
    setFormData({
      des: navire.des || "",
      article: navire.article || "",
      qte: navire.qte || "",
      contrat: navire.contrat || "",
      status: navire.status || "",
      created_by: username,
      updated_by: username,
    });
    setSelectedIdNavire(navire.id);
    setselectedProduct(navire.article); // Set selected delivery code when delivery is selected
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "article") {
      setselectedProduct(value); // Set selected delivery code when delivery is selected
    }
    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    try {
      if (selectedNavire) {
        await api.put(`navires/${selectedNavire.id}`, formData);
        setSnackbar({
          open: true,
          message: "Navire mis à jour avec succès",
          severity: "success",
        });
      } else {
        await api.post("navires", formData);
        setSnackbar({
          open: true,
          message: "Navire créé",
          severity: "success",
        });
      }
      fetchNavires();
      clearForm();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Impossible de sauvegarder le navire",
        severity: "error",
      });
    } finally {
      setFormLoading(false);
    }
  };

  const clearForm = () => {
    setFormData(initialFormState());
    setSelectedIdNavire(0);
    setSelectedNavire(null);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedNavire) {
      try {
        await api.delete(`navires/${selectedNavire.id}`);
        setSnackbar({
          open: true,
          message: "Navire supprimé",
          severity: "success",
        });
        fetchNavires();
        clearForm();
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Impossible de supprimer le navire",
          severity: "error",
        });
      } finally {
        setDeleteDialogOpen(false);
      }
    }
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Grid container spacing={1}>
      {/* Alert Table on the Left */}
      <Grid item size={{ md: 6, xs: 12 }}>
        <Box sx={{ height: { xs: "100%", md: "80vh" } }}>
          <Paper sx={{ height: "100%", overflow: "hidden" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Liste des navires
              </Typography>

              <Button
                size="small"
                onClick={clearForm}
                variant="contained"
                startIcon={<AddBoxIcon />}
              >
                Ajouter
              </Button>
            </Box>
            <Stack padding="5px" direction="row" spacing={1}>
              <Button
                size="small"
                variant={filter === "active" ? "contained" : "outlined"}
                onClick={() => setFilter("active")}
              >
                actifs 
              </Button>
              <Button
                size="small"
                variant={filter === "archived" ? "contained" : "outlined"}
                onClick={() => setFilter("archived")}
              >
                Historique
              </Button>
            </Stack>
            <Divider />

            {/* Alert Table */}
            <TableContainer sx={{ maxHeight: "70%", overflowY: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Désignation</TableCell>
                    <TableCell>Article</TableCell>
                    <TableCell>Contrats</TableCell>
                    <TableCell>Prix moyen</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingNavires ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : filteredNavires.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="left">
                        Aucun enregistrement trouvé
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredNavires.map((navire) => (
                      <TableRow
                        key={navire.id}
                        hover
                        selected={
                          selectedNavire && selectedNavire.id === navire.id
                        }
                        onClick={() => handleNavireClick(navire)}
                      >
                        <TableCell>{formatDateForDisplay1(navire.created_at)}</TableCell>
                        <TableCell>{navire.des}</TableCell>
                        <TableCell>
                          {loadingProducts ? (
                            <CircularProgress size={24} />
                          ) : (
                            products.find(
                              (product) => product.code === navire.article
                            )?.name || "N/A"
                          )}
                        </TableCell>
                        <TableCell>
                          {commandStat.find(
                            (stat) => stat.id_navire === navire.id
                          )?.nb_contrat_sum || 0}{" "}
                          / {navire.contrat}
                        </TableCell>
                        <TableCell>
                          {formatNumberForDisplay(
                            commandStat.find(
                              (stat) => stat.id_navire === navire.id
                            )? (commandStat.find((stat) => stat.id_navire === navire.id)?.pm / 
                            commandStat.find((stat) => stat.id_navire === navire.id)?.coefficient_c) : 0
                          )}
                        </TableCell>
                        <TableCell>
                          {formatNumberForDisplay(
                            commandStat.find(
                              (stat) => stat.id_navire === navire.id
                            )?.total_sum || 0
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Grid>

      {/* Form to Edit or Create Alert on the Right */}
      <Grid item size={{ md: 6, xs: 12 }}>
        <Box sx={{ height: "80vh" }}>
          <Paper sx={{ height: "100%", overflow: "hidden" }}>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {selectedNavire ? `Modifier` : "Créer"}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Button
                    size="small"
                    color="error"
                    disabled={!selectedNavire}
                    onClick={handleDeleteClick}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    supprimer
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    enregistrer
                  </Button>
                </Stack>
              </Box>
              <Stack padding="10px" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TextField
                    fullWidth
                    label="Désignation"
                    name="des"
                    value={formData.des}
                    onChange={handleFormChange}
                    margin="normal"
                    required
                    size="small"
                    sx={{ width: 200 }}
                  />
                  <FormControl margin="normal" size="small" sx={{ width: 200 }}>
                    <InputLabel>Article</InputLabel>
                    <Select
                      align="start"
                      label="Article"
                      name="article"
                      value={formData.article}
                      onChange={handleFormChange}
                      required
                    >
                      {loadingProducts ? (
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        products.map((product) => (
                          <MenuItem key={product.code} value={product.code}>
                            {product.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={2}>
                  <FormControl sx={{ width: 200 }} margin="normal" size="small">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Contrats
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={formData.contrat}
                      label="Contrats"
                      name="contrat"
                    required
                      type="number"
                      onChange={handleFormChange}
                    />
                  </FormControl>

                  <FormControl sx={{ width: 200 }} margin="normal" size="small">
                    <InputLabel>Quantité</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={formData.qte}
                      label="Quantité"
                      name="qte"
                    required
                      type="number"
                      onChange={handleFormChange}
                    />
                  </FormControl>

                  <FormControl sx={{ width: 200 }} margin="normal" size="small">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={20}
                            checked={formData.status === 20}
                            onChange={(e) =>
                              handleFormChange({
                                target: {
                                  name: "status",
                                  value: e.target.checked ? 20 : 90,
                                },
                              })
                            }
                          />
                        }
                        label="Actif"
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
                <div></div>
              </Stack>
            </form>
            <Grid padding={"5px"} sx={{ maxHeight: "50px" }}>
              <NavireDetails
                id_navire={selectedIdNavire}
                article={selectedProduct}
              />
            </Grid>
          </Paper>
        </Box>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Etes-vous sûr de vouloir confirmer la suppression?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default NavireManager;
