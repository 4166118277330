import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import LogoImage from "../../assets/LOGO-LRB.png"; // Update with the path to your logo
import Notify from "../Notifications/NotificationComponent";
import Pages from "./Pages";
import MobileMenu from "./MobileMenu";
import Data from "../Notifications/data";
import { AuthContext } from "../../authentification/auth/AuthProvider";
import Sidebar from "./Menu";
function ResponsiveAppBar() {
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        color: "black",
      }}
    >
      <Container maxWidth="100%">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "64px",
            padding: "0",
          }}
        >
          {/* Logo on the left */}
          <Box
            component="img"
            src={LogoImage}
            alt="Logo"
            sx={{
              height: { xs: "25px", md: "35px" },
              objectFit: "contain",
            }}
          />

          <Pages />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1.5, md: 3 },
              marginLeft: "auto",
            }}
          >
              <Data />
            
            <Notify />
            <MobileMenu />
            <Sidebar />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
