import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  OutlinedInput,
  CardContent,
  Card,
  Tooltip,
  Stack,
} from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid2";
import NavireTable from "./CommandList";
import CommandStat from "./CommandStat";
import CoursData from "./CoursData";
import type_market from "./marketTypes";
import {
  Prix,
  Total_cmd,
  Change,
  Prix_Brute_C,
  Prix_Net_C,
  Prix_Net_T,
} from "../../utils/Calculation";
import { useFetchData } from "../../utils/useFetchData";
import { formatNumberForDisplay } from "../../utils/FormatNumber";
import { AuthContext } from "../../authentification/auth/AuthProvider";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useNavigate } from "react-router-dom";
import { formatDateForDisplay1 } from "../../utils/dateUtils";

const API_URL = process.env.REACT_APP_API_URL;

function TooltipWithNavigation() {
  const navigate = useNavigate();

  return (
    <Tooltip
      title={
        <span>
          Aucune donnée disponible. Veuillez vérifier les paramètres du navire !{" "}
          <span
            onClick={() => navigate("/navires")}
            style={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Cliquez ici
          </span>
        </span>
      }
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "error.main",
            color: "white", // Optional: Adjust text color for visibility
          },
        },
        arrow: {
          sx: {
            color: "error.main",
          },
        },
      }}
    >
      <WarningRoundedIcon color="error" fontSize="small" />
    </Tooltip>
  );
}

const api = axios.create({
  baseURL: API_URL, // Replace with your FastAPI base URL
});

const CommandManager = () => {
  const { data: deliveries, loading: loadingDeliveries } = useFetchData(
    `${API_URL}/deliveries`
  );
  const { data: products, loading: loadingProducts } = useFetchData(
    `${API_URL}/products`
  );
  const {
    data: navires,
    loading: loadingNavires,
    fetchData: fetchNavires,
  } = useFetchData(`${API_URL}/navires`);
  const { username, role, fullname } = useContext(AuthContext);

  const [selectedNavire, setSelectedNavire] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState(initialFormState());
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [filter, setFilter] = useState("active");
  const handleReload = () => setReloadTrigger((prev) => prev + 1);
  const [selectedDeliveryCode, setSelectedDeliveryCode] = useState("aaa");
  const [selectedIdNavire, setSelectedIdNavire] = useState(0);
  const [productCoefficient, setProductCoefficient] = useState(1);
  const [productCoefficient_c, setProductCoefficient_c] = useState(1);
  const [nb_contrat_rest, setnb_contrat_rest] = useState(0);
  const [nb_contrat_sum, setnb_contrat_sum] = useState(0);
  const [last, setlast] = useState(0);
  const [last_date, setlast_date] = useState(0);
  const [settlement, setsettlement] = useState(0);
  const [Prime, setPrime] = useState(0);
  const [prix_cible, setprix_cible] = useState(0);
  const [total_n, settotal_n] = useState(0);
  const [nb_contrat_n, setnb_contrat_n] = useState(0);
  const [PN_C, setPN_C] = useState(0);
  const [Total_CMD, setTotal_CMD] = useState(0);

  const [latestData, setLatestData] = useState({});

  useEffect(() => {
    const fetchData_live = async (quote) => {
      if (quote) {
        try {
          const response = await api.get(`/data_live?quote=${quote}`);
          const data_live = response.data[0] || null;
          const last = data_live ? data_live.last : 0;
          setlast(last);
          setlast_date(data_live.date);
          setsettlement(data_live.settlement);
        } catch (error) {
        }
      }
    };

    if (selectedDeliveryCode) {
      fetchData_live(selectedDeliveryCode);
    }

    const interval = setInterval(() => {
      if (selectedDeliveryCode) {
        fetchData_live(selectedDeliveryCode);
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [selectedDeliveryCode]);

  // ----------------------------------------
  let id_navire = selectedIdNavire;
  let date1 = latestData.date || last_date;
  let cours = latestData.last || last;
  const { change: cours_change, change_per: cours_change_per } = Change(
    cours,
    settlement
  );
  let prix_settlement = (settlement + Prime) * productCoefficient;
  let prix = Prix(cours, Prime, productCoefficient);

  const { change: prix_change, change_per: prix_change_per } = Change(
    prix,
    prix_settlement
  );

  useEffect(() => {
    if (filterednavires.length > 0) {
      handleNavireClick(filterednavires[0]);
    }
  }, [navires]);

  function initialFormState() {
    return {
      delivery: "",
      des: "",
      article: "",
      qte: 0,
      prime: 0,
      type_cmd: 1,
      nb_contrat: 0,
      prix_un: 0,
      created_by: username,
      updated_by: username,
    };
  }

  useEffect(() => {
    setFormData((prevData) => {
      let updatedData = { ...prevData };

      let PB_C = Prix_Brute_C(updatedData.cours, Prime);
      setPN_C(Prix_Net_C(PB_C, productCoefficient));

      let PN_T = Prix_Net_T(PN_C, productCoefficient_c);
      setTotal_CMD(Total_cmd(formData.nb_contrat, PN_T));

      if (formData.type_cmd === 1) {
        updatedData.cours = cours || 0;
      }

      updatedData.prix_un = PN_C;
      updatedData.total = Total_CMD;

      settotal_n(updatedData.total);
      setnb_contrat_n(formData.nb_contrat);

      return updatedData;
    });
  }, [
    cours,
    formData.type_cmd,
    formData.prix_un,
    formData.cours,
    formData.nb_contrat,
    PN_C,
    Total_CMD,
    Prime,
    productCoefficient,
    productCoefficient_c,
    latestData,
  ]);

  const handleNavireClick = async (navire) => {
    setlast(0);
    setlast_date(null);
    setsettlement(0);
    initialFormState();
    setprix_cible(0);
    setSelectedDeliveryCode(navire.delivery || null);
    setSelectedNavire(navire);
    setSelectedProduct(navire.article);
    setFormData({
      nb_contrat: nb_contrat_rest,
      delivery: navire.delivery || "--",
      id_navire: navire.id || "--",
      product: navire.article || "--",
      cours: last || 0,
      type_cmd: 1,
      created_by: username,
      updated_by: username,
    });
    setSelectedIdNavire(navire.id);
    await Promise.all([
      fetchNavireData(navire.id),
      fetchProductData(navire.article),
      fetchCommandStat(navire),
    ]);
  };

  const fetchNavireData = async (id_navire) => {
    try {
      const response = await api.get(`/navired/active?id_navire=${id_navire}`);
      const fetchedData = response.data;
      if (fetchedData.length > 0) {
        const navireDetails = fetchedData[0];
        setprix_cible(navireDetails.prix_cible);
        setFormData((prevData) => ({
          ...prevData,
          prime: navireDetails.prime || 0,
          prix_cible: navireDetails.prix_cible || 0,
          delivery: navireDetails.delivery || "",
        }));
        setSelectedDeliveryCode(navireDetails.delivery);
        setPrime(navireDetails.prime || 0);
        fetchData_live(navireDetails.delivery);
      } else {
        setPrime(0);
        setprix_cible(0);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Échec de la récupération des données du navire",
        severity: "error",
      });
    }
  };

  const fetchProductData = async (product_id) => {
    try {
      const response = await api.get(`/products/${product_id}`);
      const productDetails = response.data[0];
      setProductCoefficient(productDetails.coefficient);
      setProductCoefficient_c(productDetails.coefficient_c);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Impossible de récupérer les données du produit",
        severity: "error",
      });
    }
  };

  const fetchCommandStat = async (navire) => {
    try {
      const response = await api.get(`/commands_stat?id_navire=${navire.id}`);
      const commandStat = response.data[0] || null;
      const nb_contrat_sum = commandStat ? commandStat.nb_contrat_sum : 0;
      const nb_contrat_rest = navire.contrat - nb_contrat_sum;
      setnb_contrat_sum(nb_contrat_sum);
      setnb_contrat_rest(nb_contrat_rest);
      setFormData((prevData) => ({
        ...prevData,
        nb_contrat: nb_contrat_rest > 0 ? nb_contrat_rest : 0,
      }));
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Impossible de récupérer les statistiques de commande",
        severity: "error",
      });
    }
  };

  const fetchData_live = async (quote) => {
    if (quote) {
      try {
        const response = await api.get(`/data_live?quote=${quote}`);
        const data_live = response.data[0] || null;
        const last = data_live ? data_live.last : 0;
        setlast(last);
        setlast_date(data_live.date);
        setsettlement(data_live.settlement);
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Impossible de récupérer les données en temps réel",
          severity: "error",
        });
      }
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "nb_contrat" || name === "cours" ? Number(value) : value,
    }));
    if (name === "delivery") {
      setSelectedDeliveryCode(value);
      fetchNavireData(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setFormData((prevState) => ({
        ...prevState,
      }));

      await api.post("/commands", formData);
      setSnackbar({
        open: true,
        message: "Nouvelle commande créée",
        severity: "success",
      });
      Reset_0();
      clearForm();
      setReloadTrigger((prev) => prev + 1);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Impossible d'enregistrer la commande",
        severity: "error",
      });
    }
  };

  const Reliquat = async (e) => {
    setFormData((prevData) => ({
      ...prevData,
      nb_contrat: nb_contrat_rest,
    }));
  };

  const Reset_0 = async (e) => {
    setFormData((prevData) => ({
      ...prevData,
      nb_contrat: 0,
    }));
  };

  const clearForm = () => {};
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const filterednavires = useMemo(() => {
    return navires.filter(
      (navire) =>
        (filter === "active" && navire.status < 90) ||
        (filter === "archived" && navire.status >= 90)
    );
  }, [navires, filter]);

  return (
    <Grid container spacing={1}>
      <Grid item size={{ md: 3, xs: 12 }}>
        <Box sx={{ height: { xs: "100%", md: "80vh" } }}>
          <Paper sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Liste des navires
              </Typography>
            </Box>
            <Stack padding="5px" direction="row" spacing={1}>
              <Button
                size="small"
                variant={filter === "active" ? "contained" : "outlined"}
                onClick={() => setFilter("active")}
              >
                actifs
              </Button>
              <Button
                size="small"
                variant={filter === "archived" ? "contained" : "outlined"}
                onClick={() => setFilter("archived")}
              >
                Historique
              </Button>
            </Stack>
            <Divider />
            <TableContainer sx={{ maxHeight: "70%", overflowY: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  sx={{
                    display: "table-header-group",
                    "background-color": "rgb(250, 250, 250)",
                    "border-top": "1px solid #f0f0f0",
                    "border-bottom": "2px solid #f0f0f0",
                  }}
                >
                  <TableRow sx={{ "background-color": "rgb(250, 250, 250)" }}>
                    <TableCell>Date</TableCell>
                    <TableCell>Désignation</TableCell>
                    <TableCell>Contrats</TableCell>
                  </TableRow>
                </TableHead>
                {loadingNavires ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : filterednavires.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Aucun enregistrement trouvé
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableBody>
                    {filterednavires.map((navire) => (
                      <TableRow
                        key={navire.id}
                        hover
                        selected={
                          selectedNavire && selectedNavire.id === navire.id
                        }
                        onClick={() => handleNavireClick(navire)}
                        sx={{
                          backgroundColor:
                            selectedNavire && selectedNavire.id === navire.id
                              ? "#cfe8fc"
                              : "inherit",
                        }}
                      >
                        <TableCell>
                          {formatDateForDisplay1(navire.created_at)}
                        </TableCell>

                        <TableCell>{navire.des}</TableCell>
                        <TableCell>{navire.contrat}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Grid>

      {/* Form to Edit or Create Alert on the Right */}
      <Grid item size={{ md: 9, xs: 12 }}>
        <Box>
          <Paper sx={{ padding: "5px", height: "80vh", overflowY: "auto" }}>
            <Grid container spacing={1}>
              <Grid component={Paper} size={{ xs: 12, sm: 6, md: 6 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "5px",
                    textAlign: "start",
                  }}
                >
                  {"Détails du Navire"}
                </Typography>
                <CardContent sx={{ padding: "10px" }}>
                  <Grid container spacing={1}>
                    {/* Navire */}
                    <Grid item size={{ xs: 6, sm: 6, md: 3 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "start", fontWeight: "bold" }}
                        >
                          Navire
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "start" }}>
                          {selectedNavire ? selectedNavire.des : "--"}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Article */}
                    <Grid item size={{ xs: 6, sm: 6, md: 3 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "start", fontWeight: "bold" }}
                        >
                          Article
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "start" }}>
                          {loadingProducts ? (
                            <CircularProgress size={10} />
                          ) : (
                            products
                              .filter(
                                (product) => product.code === selectedProduct
                              )
                              .map((filteredProduct) => (
                                <>{filteredProduct.name}</>
                              ))
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* Quantité */}
                    <Grid item size={{ xs: 6, sm: 6, md: 3 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "start", fontWeight: "bold" }}
                        >
                          Quantité
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "start" }}>
                          {selectedNavire ? selectedNavire.qte : "--"}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Contrat */}
                    <Grid item size={{ xs: 6, sm: 6, md: 3 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "start", fontWeight: "bold" }}
                        >
                          Contrats
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "start" }}>
                          {nb_contrat_sum} /{" "}
                          {selectedNavire ? selectedNavire.contrat : "--"}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* Prime */}
                    <Grid item size={{ xs: 6, sm: 6, md: 3 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "start", fontWeight: "bold" }}
                        >
                          Prime
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "start" }}>
                          {Prime !== 0 ? Prime : <TooltipWithNavigation />}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Prix moyen cible */}
                    <Grid item size={{ xs: 6, sm: 6, md: 4 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "start", fontWeight: "bold" }}
                        >
                          Prix moyen cible
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "start" }}>
                          {prix_cible !== 0 ? (
                            prix_cible
                          ) : (
                            <TooltipWithNavigation />
                          )}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Livraison */}
                    <Grid item size={{ xs: 12, sm: 6, md: 4 }}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "start", fontWeight: "bold" }}
                        >
                          Livraison
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "start" }}>
                          {loadingDeliveries ? (
                            <CircularProgress size={10} />
                          ) : deliveries.filter(
                              (d) => d.code === selectedDeliveryCode
                            ).length > 0 ? (
                            deliveries
                              .filter((d) => d.code === selectedDeliveryCode)
                              .map((delivery) => <>{delivery.name}</>)
                          ) : (
                            <TooltipWithNavigation />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
              <Grid component={Paper} size={{ xs: 12, sm: 6, md: 6 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "5px",
                    textAlign: "start",
                  }}
                >
                  {"Nouvelle Commande : Saisie Rapide"}
                </Typography>
                <CardContent sx={{ padding: "10px" }}>
                  <form onSubmit={handleSubmit}>
                    <Box display="flex" alignItems="center">
                      <FormControl sx={{ width: 150 }} size="small">
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Contrats
                        </InputLabel>
                        <OutlinedInput
                          autoFocus
                          id="outlined-adornment-amount"
                          label="Contrat"
                          name="nb_contrat"
                          type="number"
                          value={formData.nb_contrat} // Fixed the value key to match 'name'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              value === "" ||
                              (Number(value) <= 999 && value.length <= 3)
                            ) {
                              handleFormChange(e); // Only update form data if the value is valid
                            }
                          }}
                          required
                          sx={{ marginRight: 2 }}
                          inputProps={{ min: 1, max: 999 }} // Added min and max to restrict the value range
                        />
                      </FormControl>
                      <FormControl sx={{ width: 300 }} size="small">
                        <InputLabel>Type</InputLabel>
                        <Select
                          align="start"
                          label="Type"
                          name="type_cmd"
                          value={formData.type_cmd} // Fixed the value key to match 'name'
                          onChange={handleFormChange}
                          required
                        >
                          {type_market.map((type_cmd) => (
                            <MenuItem
                              key={type_cmd.value}
                              value={type_cmd.value}
                            >
                              {type_cmd.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <FormControl
                        sx={{ width: 150 }}
                        margin="normal"
                        size="small"
                      >
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          name="cours"
                          value={
                            formData.type_cmd === 1
                              ? formatNumberForDisplay(cours)
                              : null
                          }
                          sx={{ marginRight: 2 }}
                          onChange={handleFormChange}
                          disabled={formData.type_cmd === 1}
                        />
                      </FormControl>
                      <FormControl
                        sx={{ width: 150 }}
                        margin="normal"
                        size="small"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Prix
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          value={formatNumberForDisplay(formData.prix_un)}
                          label="Prix"
                          name="prix"
                          onChange={handleFormChange}
                          sx={{ marginRight: 2 }}
                          disabled
                        />
                      </FormControl>
                      <FormControl
                        sx={{ width: 150 }}
                        margin="normal"
                        size="small"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Total
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          value={formatNumberForDisplay(formData.total)}
                          label="Total"
                          name="total"
                          onChange={handleFormChange}
                          disabled
                        />
                      </FormControl>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {formData.nb_contrat !== 0 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={Reset_0}
                        >
                          Remettre à 0
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={Reliquat}
                        >
                          Reliquat : {nb_contrat_rest}
                        </Button>
                      )}
                      <Box display="flex" alignItems="center">
                        {deliveries.filter(
                          (d) => d.code === selectedDeliveryCode
                        ).length > 0 ? (
                          <></>
                        ) : (
                          <TooltipWithNavigation />
                        )}
                        <Button
                          sx={{ width: 150 }}
                          type="submit"
                          size="small"
                          variant="contained"
                          color="success"
                          startIcon={<SaveIcon />}
                          disabled={
                            deliveries.filter(
                              (d) => d.code === selectedDeliveryCode
                            ).length === 0
                          }
                        >
                          enregistrer
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </CardContent>
              </Grid>

              <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                <CoursData
                  cours={cours}
                  prix={prix}
                  prix_change={prix_change}
                  prix_change_per={prix_change_per}
                  cours_change={cours_change}
                  cours_change_per={cours_change_per}
                  date={date1}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                <CommandStat
                  id_navire={id_navire}
                  prix_cible={prix_cible}
                  nb_contrat_n={nb_contrat_n}
                  total_n={total_n}
                  reloadTrigger={reloadTrigger}
                  productCoefficient_c={productCoefficient_c}
                />
              </Grid>

              <Grid container sx={{ width: "100%" }}>
                <Card
                  sx={{ width: "100%", height: { xs: "actual", md: "100%" } }}
                >
                  <NavireTable
                    id_navire={id_navire}
                    reloadTrigger={reloadTrigger}
                    setReloadTrigger={setReloadTrigger}
                    productCoefficient_c={productCoefficient_c}
                    productCoefficient={productCoefficient}
                  />
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Grid>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default CommandManager;
